import axios from "axios";

import axiosRetry from "axios-retry";

export const api_request = () => {
  const _axios = new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  axiosRetry(_axios, { retries: 3, shouldResetTimeout: true });
  return _axios;
};

export const checkAuth = (resp) => {
  //TODO:
  //Impement on components on catch: If status is 401 execute Google Signout
  // console.log(resp.response.status);
};

export const staffs_get = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/staff`, {
        params: filter,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const crew_staffs_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/staff/crew")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_get = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/staff/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/staff", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_update_last_activity_post = (auth_data, _id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/staff/update-last-activity", { _id })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_last_activity_get = (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/staff/last-activity")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_put = async (auth_data, body, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/staff/${id}`, body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_delete = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/staff/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
